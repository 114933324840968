
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
  page: {
    title: "Pre Buy Vouchers Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      tableData: [],

      title: "Pre Buy Vouchers ",
      items: [
        {
          text: "View",
        },
        {
          text: "Pre Buy Vouchers",
          active: true,
        },
      ],
      city: "",
      cityArr: [],
      countryArr: [],
      brandArr: [],
      brandData: "",
      country: "",
      daterange: [],
      branches: [],
      restaurants: [],
      restaurantID: "",
      restBranchID: "",
      branchID: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "voucherType",
          sortable: true,
        },
        {
          key: "voucherTitle",
          sortable: true,
        },
        {
          key: "shortDesc",
          sortable: true,
        },
        {
          key: "restriction",
          sortable: true,
        },
        {
          key: "noOfVouchers",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "product",
          sortable: true,
        },
        {
          key: "oneVoucherPrice",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "sellingPrice",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "validFor",
          sortable: true,
        },
        {
          key: "voucherStatus",
          sortable: true,
        },
        {
          key: "created",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    if (this.$storageData.login_type  == 2 || this.$storageData.profile.empTypeID == 11) {
      this.getRestaurantCountry();
    } else {
      this.getAllPreBuyVouchers();
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    downloadSample() {
      this.axios
        .post(this.$loggedRole+"/exportExcel",
          {
            exportData: this.tableData,
            page: "PreBuyVoucher",
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "pre-buy-vouchers.xlsx");
          document.body.appendChild(link);
          link.click();
        });
    },

    getAllPreBuyVouchers() {
      this.axios
        .post(this.$loggedRole+"/getAllPreBuyVouchers", {
          restBranchID:
            this.$storageData.login_type == 2 ||
            this.$storageData.profile.empTypeID == 2 ||
            this.$storageData.profile.empTypeID == 11
              ? this.restBranchID
              : this.$storageData.profile.restBranchID,
          daterange: this.daterange,
        })
        .then((response) => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //console.log((response));
        });
    },

    changeVoucherStatus(voucherID) {
      if (confirm("Do you really want to stop this voucher plan?")) {
        this.axios
          .post(this.$loggedRole+"/changeVoucherStatus", { voucherID: voucherID })
          .then(() => {
            this.getAllPreBuyVouchers();
          });
      }
    },
    getRestaurantBrandsByCompanyID() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
          companyID: this.$storageData.profile.companyID,
        })
        .then((response) => {
          this.brandArr = response.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", { restID: this.$storageData.profile.restID })
        .then((response) => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getRestaurantCities();
        });
    },
    getRestaurantCities() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID:
            this.$storageData.login_type == 1
              ? this.restaurantID.restID
              : this.$storageData.profile.restID,
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          if (this.$storageData.profile.empTypeID == 11) {
            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },

    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID: this.brandData
            ? this.brandData.restID
            : this.$storageData.profile.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : "",
        })
        .then((response) => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : "";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          this.getAllPreBuyVouchers();
        });
    },
    onchangeBranch() {
      this.restBranchID = this.branchID.restBranchID;
      this.getAllPreBuyVouchers();
    },
    clearFilter() {
      this.restBranchID = this.branchID.restBranchID;
      this.daterange = [];
      this.getAllPreBuyVouchers();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div class="row">
            <div
              class="col-md-2"
              v-if="
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
              "
            >
              <label>Select Country </label>
              <multiselect
                v-model="country"
                :options="countryArr"
                track-by="country"
                label="country"
                @input="getRestaurantCities()"
                :show-labels="false"
              ></multiselect>
            </div>
            <!-- Cities List-->
            <div
              class="col-md-2"
              v-if="
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
              "
            >
              <label>Select City </label>
              <multiselect
                v-model="city"
                :options="cityArr"
                track-by="city"
                label="city"
                @input="readRestaurantBranchData()"
                :show-labels="false"
              ></multiselect>
            </div>
            <!--END Cities List-->
            <div class="col-md-3 mb-3" v-if="this.$storageData.profile.empTypeID == 11">
              <label>Select Restaurant Brand</label>
              <multiselect
                v-model="brandData"
                :options="brandArr"
                track-by="restID"
                label="name"
                @input="readRestaurantBranchData()"
                :show-labels="false"
              ></multiselect>
            </div>
            <div
              class="col-md-3"
              v-if="
                this.$storageData.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
              "
            >
              <label>Select Restaurant Branch </label>
              <multiselect
                v-model="branchID"
                :options="branches"
                :show-labels="false"
                track-by="restBranchID"
                label="branchName"
                @input="onchangeBranch()"
              ></multiselect>
            </div>

            <div class="col-md-3">
              <label>Date Range* </label>
              <date-picker
                v-model="daterange"
                format="DD MMM Y"
                range
                append-to-body
                lang="en"
                confirm
                value-type="format"
                @input="onchangeBranch()"
              ></date-picker>
            </div>
            <div class="col-md-1">
              <button
                v-if="daterange.length > 0"
                class="btn btn-themeBrown mt-4"
                v-on:click="clearFilter()"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 30px">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4" style="margin-bottom: 15px;" v-if="this.$storageData.login_type == 1">
                <div role="group" class="btn-group">
                  <button
                    type="button"
                    class="btn btn-themeBrown"
                    @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      style="margin-left: 5px; margin-right: 5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped
                hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                fixed-header
              >
                <!-- <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                </template> -->
                <template v-slot:cell(Action)="data">
                  <a
                    href="#"
                    @click="changeVoucherStatus(data.item.voucherID)"
                    v-if="data.item.voucherStatus == 1"
                  >
                    <button
                      title="Stop Voucher"
                      class="btn btn-themeYellow"
                      style="padding: 1px 11px"
                    >
                      Stop
                    </button>
                  </a>
                </template>

                <template v-slot:cell(oneVoucherPrice)="data">
                  <span v-if="data.item.oneVoucherPrice"
                    >₹{{ data.item.oneVoucherPrice }}</span
                  >
                </template>
                <template v-slot:cell(sellingPrice)="data">
                  <span v-if="data.item.sellingPrice"
                    >₹{{ data.item.sellingPrice }}</span
                  >
                </template>
                <template v-slot:cell(validFor)="data">
                  <span v-if="data.item.validFor"
                    >{{ data.item.validFor }} days</span
                  >
                </template>

                <template v-slot:cell(product)="data">
                  <span v-if="data.item.product">{{ data.item.product }}</span>
                  <span v-else> - </span>
                </template>

                <template v-slot:cell(voucherStatus)="data">
                  <span
                    v-if="data.item.voucherStatus == 1"
                    style="color: green"
                  >
                    Active
                  </span>
                  <span v-else style="color: red"> Stopped </span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

